type Extras = { [name: string]: any };

export type ErrorLoggerHandler = (options: {
  error: any;
  userId?: string;
  extras?: Extras;
  reference?: string;
}) => { error: any; extras?: Extras; reference?: string };

type Metadata = { userId?: string; extras: Extras };

type MetadataListener = (metadata: Metadata) => void;

class ErrorLogger {
  handlers: ErrorLoggerHandler[] = [];

  references = new WeakMap<any, string>();

  userId?: string;

  extras: Extras = {};

  metadataListeners: MetadataListener[] = [];

  addHandler(handler: ErrorLoggerHandler) {
    this.handlers.push(handler);
  }

  removeHandler(handler: ErrorLoggerHandler) {
    this.handlers = this.handlers.filter((h) => h !== handler);
  }

  log(error: any, extras?: Extras) {
    let handlerInputOutput = {
      error,
      extras: extras ? { ...this.extras, ...extras } : this.extras,
      userId: this.userId,
      reference: this.references.get(error),
    };

    // Don´t handle errors again if reference has been set.
    if (!handlerInputOutput.reference) {
      this.handlers.forEach((handler) => {
        handlerInputOutput = handler(handlerInputOutput) || handlerInputOutput;
      });

      console.error(error);

      if (handlerInputOutput.reference)
        this.references.set(
          handlerInputOutput.error,
          handlerInputOutput.reference
        );
    }

    return handlerInputOutput.error;
  }

  warn(error: any) {
    if (console.warn) console.warn(error);
    else console.log(error);
  }

  getReference(error: any) {
    return this.references.get(error);
  }

  setReference(error: any, reference: string) {
    this.references.set(error, reference);
  }

  setUserId(userId?: string) {
    this.userId = userId;
    this.metadataDidChange();
  }

  setExtra(name: string, value: any) {
    this.extras[name] = value;
    this.metadataDidChange();
  }

  addMetadataListener(listener: MetadataListener) {
    this.metadataListeners.push(listener);
    return () => this.removeMetadataListener(listener);
  }

  removeMetadataListener(listener: MetadataListener) {
    const index = this.metadataListeners.indexOf(listener);
    if (index !== -1)
      this.metadataListeners = this.metadataListeners.splice(index, 1);
  }

  metadataDidChange() {
    this.metadataListeners.forEach((listener) => listener(this.getMetadata()));
  }

  getMetadata() {
    return {
      userId: this.userId,
      extras: this.extras,
    };
  }

  setMetadata(metadata: Metadata) {
    if ("userId" in metadata) this.userId = metadata.userId;
    if ("extras" in metadata)
      this.extras = { ...this.extras, ...metadata.extras };
    this.metadataDidChange();
  }
}

const errorLogger = new ErrorLogger();

export default errorLogger;
