import config from "../config";
import appInfo from "../__generated__/appInfo";
import errorLogger from "./ErrorLogger";
import getUserFriendlyErrorMessage from "./getUserFriendlyErrorMessage";

// Since sentry does not provide an isomorphic interface
export default function initErrorLogger({
  sentry,
  breadCrumbs,
}: {
  sentry: any;
  breadCrumbs?: boolean;
}): void {
  if (config.sentry?.dsn && appInfo.mode === "production") {
    errorLogger.addHandler((props) => {
      if (!props.reference && !getUserFriendlyErrorMessage(props.error)) {
        sentry.withScope((scope) => {
          if (props.userId) scope.setUser({ id: props.userId });
          if (props.extras) scope.setExtras(props.extras);
          if (props.error instanceof Error)
            sentry.captureException(props.error);
          else {
            sentry.setExtra("error", props.error);
            sentry.captureMessage(props.error.message);
          }
        });
        return {
          ...props,
          reference: sentry.lastEventId ? sentry.lastEventId() : "",
        };
      }

      return props;
    });

    sentry.init({
      dsn: config.sentry.dsn,
      release: `${appInfo.name}@${appInfo.version}`,
      beforeSend(event) {
        // We don't want to send query params, as they can contain sensitive information like tokens etc
        if (event.request?.url.includes("?"))
          event.request.url = event.request.url.substring(
            0,
            event.request.url.indexOf("?")
          );
        return event;
      },
      integrations: (integrations: any[]) =>
        integrations.filter(
          ({ name }) =>
            (breadCrumbs || name !== "Breadcrumbs") && name !== "Dedupe"
        ),
    });
  }
}
